import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sideBar: sessionStorage.getItem("collapse") ? JSON.parse(sessionStorage.getItem("collapse")) : !(window.innerWidth > 768),
    user: localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):null,
    menu: localStorage.getItem('menu')?JSON.parse(localStorage.getItem('menu')):null,
    permission: localStorage.getItem('permission')?JSON.parse(localStorage.getItem('permission')):null,
    noticeCount:0,
  },
  getters: {
    userInfo (state) {
      return state.user ? state.user : {
        profile_photo: {},
        qiniu_photo: {
          signedUrl:'',
          thumbUrl:'' 
        },
        id:'',
        name: '',
        sex:'',
        username:'',
        phone: '',
        email:'',
        remark:'',
        select_role:'',
        roles:[],
        status: '',
        money_num:'',
        department_id:'',
        department:{},
        entry_time:'',
        deleted_at:'',
      };
    },
    username (state) {
      return state.user ? state.user.username : "";
    },
    email (state) {
      return state.user? state.user.email : "";
    },
    roleInfo (state) {
      return state.user? state.user.roles : [];
    },
    menu (state) {
      return state.menu ? state.menu : [];
    },
    permission (state) {
      return state.permission ? state.permission : [];
    },
  },
  mutations: {
    SET_USER (state, val) {
      state.user = val;
    },
    SET_MENU (state, val) {
      state.menu = val;
    },
    SET_PERMS (state, val) {
      state.permission = val;
    },
    SET_SIDEBAR (state, val) {
      state.sideBar = val;
    },
    SET_NOTICECOUNT (state, val) {
      state.noticeCount = val;
    },
  },
  actions: {
    setUser ({ commit }, val) {
      commit("SET_USER", val);
    },
    setMenu ({ commit }, val) {
      commit("SET_MENU", val);
    },
    setPerms ({ commit }, val) {
      commit("SET_PERMS", val);
    },
    setSideBar ({ commit }, val) {
      commit("SET_SIDEBAR", val);
    },
    setNoticeCount ({ commit }, val) {
      commit("SET_NOTICECOUNT", val);
    },
  },
  modules: {
  }
})
